<template>
  <div
    class="app-container apply__signUp"
    style="padding: 180px 0px 120px;"
  >
    <el-form
      ref="signUpForm"
      class="custom-form apply__signUp--container"
      :model="signUpForm"
      :rules="basicRules"
      inline
    >
      <div class="w-3/5 inline-block align-top leftSide">
        <h1 class="apply__tit font-bold text-2xl mt-5 mb-10 mt-10">
          <span class="highlight">
            <span>📝 지원서 등록</span>
          </span>
          <span
            class="ml-1 font-normal myApply__link"
            style="font-size: 15px;"
          >
            이미 지원하셨었나요?
            <router-link
              class="ml-1 font-bold"
              to="/recruit/my-apply"
            ><span class="underline">내 지원서 바로가기</span></router-link>
          </span>
        </h1>
        <div>
          <el-form-item prop="name">
            <el-input
              v-model.trim="signUpForm.name"
              :placeholder="$t('recruit.form.name')"
              name="namefield"
              class="filter-item"
              style="width: 235px"
            />
          </el-form-item>
          <el-form-item prop="birthday">
            <el-input
              v-model.trim="signUpForm.birthday"
              :maxlength="10"
              :placeholder="$t('recruit.form.birthday')"
              class="filter-item"
              style="width: 235px"
              @keydown.native="autoCompleteBirthday"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="phone">
            <el-input
              v-model.trim="signUpForm.phone"
              :maxlength="11"
              :placeholder="$t('recruit.form.phone')"
              type="tel"
              class="filter-item"
              style="width: 235px"
            />
          </el-form-item>
          <el-form-item
            class="email__ipt"
            prop="email"
          >
            <p class="font-primary-color ml-1 text-sm mb-2">
              <i
                class="highlight__star"
                style="font-size: 16px; top: 4px;"
              >*</i>
              비밀번호 분실 시, 비밀번호 변경을 위한 사용자 인증 이메일 주소를 입력해주세요.
            </p>
            <el-input
              v-model.trim="signUpForm.email"
              :minlength="10"
              :placeholder="$t('recruit.form.email')"
              type="email"
              class="filter-item"
              style="width: 480px"
            />
          </el-form-item>
        </div>
        <div>
          <p class="font-primary-color ml-1 text-sm mb-2">
            <i
              class="highlight__star"
              style="font-size: 16px; top: 4px;"
            >*</i>
            비밀번호는 영문, 숫자를 포함하여 공백없이 8 ~ 20자로 설정해주세요.
          </p>
          <el-form-item prop="password">
            <el-input
              v-model.trim="signUpForm.password"
              show-password
              :minlength="8"
              :maxlength="20"
              :placeholder="$t('recruit.form.password')"
              type="password"
              class="filter-item"
              style="width: 235px"
            />
          </el-form-item>
          <el-form-item prop="passwordConfirm">
            <el-input
              v-model.trim="signUpForm.passwordConfirm"
              show-password
              :minlength="8"
              :maxlength="20"
              :placeholder="$t('recruit.form.passwordConfirm')"
              type="password"
              class="filter-item"
              style="width: 235px"
            />
          </el-form-item>
        </div>
        <div
          class="text-sm mt-6 mr-5 pt-5 text-gray-500"
          style="border-top: 1px solid rgb(233, 232, 232);"
        >
          <p style="text-indent: -17px; margin-left: 18px;">
            ✔ 서류 결과 안내는 근무일 기준 5일 이내에 전달해 드리고 있으며 지원 폭주로 인해 안내가 지연되는 경우에는 일주일 전 후로 서류결과 안내 이메일이 발송됩니다. 
          </p>
          <p style="text-indent: -17px; margin-left: 18px;">
            ✔ 위의 정보는 지원서 관리 및 결과확인을 위하여 반드시 필요하므로 잘 관리해 주시기 바랍니다.
          </p>
        </div>
      </div>
      <div class="w-2/5 h-full inline-block align-top py-20 rightSide">
        <div
          class="w-full h-full"
          style="border-left: 1px solid rgb(233, 232, 232);"
        >
          <p>
            <el-checkbox
              v-model="privacyCheck"
              class="mt-4 ml-8 mb-4"
            >
              개인정보의 수집 및 이용에 동의
              <i class="highlight__star required">*</i>
            </el-checkbox>
          </p>
          <div
            class="overflow-y-auto ml-8 border-gray-300 box-border"
            style="height: 118px; border: 1px solid rgb(233, 232, 232);"
          >
            <div class="text-sm text-gray-500 pr-4 pl-4">
              <p
                class="mt-3"
                style="text-indent: 4px;"
              >
                (주)쿡앱스는 지원자의 입사전형 진행 및 인재풀 구성을 위해 아래와 같은 목적으로 개인정보를 수집 및 이용합니다.
              </p>
              <ul>
                <li>이력서 및 경력기술서 제출 시 전형결과 안내 및 자격요건 확인에 필요한 최소한의 개인 정보만 기입해주시길 바랍니다. </li>
                <li>주민번호, 가족관계, 종교, 신체정보 등의 불필요한 개인 정보는 삭제해주시길 바랍니다.</li>
              </ul>
              <div class="mb-3">
                <br>
                <h4 class="mt-2 mb-2 font-bold">
                  1.개인정보의 수집 및 이용 목적
                </h4><br>
                <p class="ml-3">
                  입사전형 진행,자격요건 확인, 전형별 합격여부 확인,전형결과 안내
                </p><br>
                <h4 class="mt-2 mb-2 font-bold">
                  2. 수집하는 개인정보의 항목
                </h4><br>
                <p class="ml-3">
                  필수정보 : 성명, 생년월일,이메일주소, 휴대전화번호,국적,경력사항(입사일,퇴사일,회사명,직급,담당업무,프로젝트 수행이력)
                  학력,전공,병역사항,생성정보(접속정보,로그기록 등)
                </p>
                <p class="ml-3">
                  선택정보 : 자격사항, 수상내역, 어학사항, 봉사활동, 보훈사항, 논문사항 등
                </p><br>
                <h4 class="mt-2 mb-2 font-bold">
                  3. 개인정보의 보유 및 이용기간
                </h4><br>
                <p class="ml-3">
                  1) 채용여부 확정 이후 최대 3년까지 보관되며 이후 지체없이 파기합니다.
                </p>
                <p class="ml-3">
                  2) 보관기간 이내라도 입사지원자가 개인정보의 삭제를 원하는 경우 지체없이 해당 정보를 파기합니다.
                </p>
              </div>
            </div>
          </div>
          <p>
            <el-checkbox
              v-model="signUpForm.recruitPoolCheck"
              class="mt-6 ml-8 mb-4"
            >
              인재풀 등록 및 채용정보 수신 동의
            </el-checkbox>
          </p>
          <div
            class="overflow-y-auto ml-8 border-gray-300 box-border"
            style="height: 90px; border: 1px solid rgb(233, 232, 232);"
          >
            <div class="text-sm text-gray-500 pr-4 pl-4">
              <p
                class="mt-3"
                style="text-indent: 4px;"
              >
                입사지원자는 상기 개인정보를 상시채용을 위해 인력풀에 등록하여
                <span
                  class="font-primary-color"
                  style="font-weight: bold;"
                >5년간 보유하는 것에</span>
                동의하며, 이메일로 쿡앱스 채용정보를 받아보겠습니다.
              </p>
              <p
                class="mt-3"
                style="text-indent: 4px;"
              >
                인재풀에 등록될 경우 관련 직무 채용 관련 이메일 수신에 동의하며 수신을 원하지 않을 경우 언제든지 거부 요청할 수 있습니다.
              </p>
            </div>
          </div>
          <div class="applyNew__btnWrap">
            <button
              class="customBtn lg"
              @click.prevent="createAccount"
            >
              <span style="font-size: 18px;">지원하기</span>
            </button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { signup, login } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  data() {
    return {
      privacyCheck: false,
      signUpForm: {
        name: '',
        birthday: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirm: '',
        recruitPoolCheck: false,
      },
      basicRules: {
        name: [{required: true, trigger: 'blur'}],
        birthday: [{required: true, trigger: 'blur'}],
        email: [{required: true, trigger: 'blur'}],
        phone: [{required: true, trigger: 'blur'}],
        password: [{required: true, trigger: 'blur'}],
        passwordConfirm: [{required: true, trigger: 'blur'}],
      },
    };
  },
  methods: {
    autoCompleteBirthday: function (key) {
      const birthday = this.signUpForm.birthday;
      const year = birthday.substr(0,4);
      const firstDot = birthday.substr(4,1);
      const month = birthday.substr(5,2);
      const secondDot = birthday.substr(7,1);
      if( key.keyCode == 8 || key.keyCode == 46 || key.key === '.' ) return false;
      if(birthday.length > 3 && firstDot !== '.'){
        this.signUpForm.birthday = year + '.';
      }else if(birthday.length > 6 && secondDot !== '.'){
        this.signUpForm.birthday = year + '.' + month + '.';
      }
    },
    validateBirthday() {
      let isBirthdayValid = false;
      const birthday = this.signUpForm.birthday;
      var regex = /^(19[0-9][0-9]|20\d{2}).(0[0-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/;
      if(!regex.test(birthday)){
        this.$message({
          message: '생년월일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isBirthdayValid = true;
      }
      return isBirthdayValid;
    },
    validateEmail() {
      let isEmailValid = false;
      const email = this.signUpForm.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: '이메일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validateSignUpForm() {
      let isValid = false;
      this.$refs['signUpForm'].validate((valid) => {
        isValid = valid;
      });
      return isValid;
    },
    validatePhone() {
      let isPhoneVaild = false;
      const phone = this.signUpForm.phone;
      const regex = /^\d{3}\d{4}\d{4}$/;
      if(!regex.test(phone)){
        this.$message({
          message: '연락처는 숫자로만 11자리를 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isPhoneVaild = true;
      }
      return isPhoneVaild;
    },
    validatePassword() {
      let isPwVaild = false;
      const pw = this.signUpForm.password;
      const pwConfirm = this.signUpForm.passwordConfirm;
      const num = pw.search(/[0-9]/g);
      const eng = pw.search(/[a-z]/ig);

      if(pw.length < 8 || pw.length > 20){
        this.$message({
          message: '비밀번호는 8자리 ~ 20자리 이내로 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw.search(/\s/) != -1){
        this.$message({
          message: '비밀번호는 공백 없이 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(num < 0 || eng < 0 ){
        this.$message({
          message: '비밀번호를 영문,숫자를 혼합하여 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw !== pwConfirm){
        this.$message({
          message: '비밀번호가 다릅니다.',
          type: 'error'
        });
      }else {
        isPwVaild = true;
      }
      return isPwVaild;
    },
    async createAccount() {
      if (!this.validateSignUpForm()) {
        this.$message({
          message: '필수 입력 항목들을 모두 채워주세요.',
          type: 'error'
        });
        return false;
      }else if (!this.privacyCheck) {
        this.$message({
          message: '개인정보 수집 및 이용에 동의해주세요.',
          type: 'error'
        });
        return false;
      }else if (!this.validateBirthday() || !this.validatePhone() || !this.validateEmail() || !this.validatePassword()) {
        return false;
      }else{
        // dataLayer.push({
        //   event: 'clickCreateAccount',
        // createAccountJob: this.jobName,
        // });

        const signUpResult = await signup(this.signUpForm);
        if(!signUpResult.data.success){
          this.$message({
            message: signUpResult.data.message,
            type: 'error'
          });
        }else{
          const loginResult = await login(this.signUpForm);
          await this.$store.dispatch('user/setToken', loginResult.data.token);
          await this.$store.dispatch('user/setUid', loginResult.data.uid);
          await this.$store.dispatch('user/setUserForm',this.signUpForm);
          this.$store.dispatch('app/setIsSimpleApply',false);
          this.$router.push('/recruit/apply?status=signUpComplete');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/apply.scss';
</style>
<style lang="scss">
.apply__signUp .rightSide .el-checkbox__label{
    font-size: 16px;
}
.apply__signUp .rightSide .el-checkbox__inner{
    width: 17px;
    height: 17px;
    position: relative;
    bottom: 2px;
}
.apply__signUp .rightSide .el-checkbox__inner::after{
    left: 5px;
    width: 4px;
    height: 9px;
}
.apply__signUp .applyPool__check .el-checkbox__label{
    white-space: normal;
}
.apply__signUp .applyPool__check .el-checkbox__input{
    vertical-align: top;
    top: 6px;
}
</style>
